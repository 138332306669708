<template>
    <nav class="tab_top" @click="toggleNav($event)">
        <router-link
            v-for="(item, i) in this.navList"
            :key="i"
            :to="item.to"
            :class="this.$route.path.includes(item.to) ? 'router-link-active' : ''"
        >
            {{item.content}}
        </router-link>
    </nav>
</template>

<script>
export default {
    name : 'LayoutTabTop',
    props : {
        navList : Array,
    },
    mounted(){
        window.addEventListener('resize', this.checkTabSize);
    },
    unmounted(){
        window.removeEventListener('resize', this.checkTabSize)
    },
    methods : {
        toggleNav(event){
            event.currentTarget.classList.toggle('open');
        },
        checkTabSize(event){
            if(event.target.innerWidth > 768){
                document.querySelector('.tab_top').classList.remove('open');
            }
        },
    }
}
</script>

<style scoped>
/* 최상단 탭 START */
.tab_top {
    margin: 5rem 0;
    display: flex;
    justify-content: center;
    border-bottom: 0.1rem solid var(--border-color-default);
}
.tab_top a {
    display: block;
    flex-basis: 33.3%;
    font-size: var(--font-mid);
    font-weight: var(--font-w-bold);
    line-height: 2.8;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.tab_top .router-link-active{
    color: var(--primary-color);
    font-weight: var(--font-w-exbold);
}
@media screen and (max-width: 768px) {
    .tab_top {
        margin: 3rem 0;
        flex-wrap: wrap;
        justify-content: space-between;
        border: 0.1rem solid var(--border-color-default);
        border-radius: var(--border-radius-mid);
    }
    .tab_top a {
        flex-basis: 100%;
        display: none;
    }
    .tab_top .router-link-active{
        background:url("~@/assets/image/common/icon_arrow_down.svg") center right 2rem / 2rem no-repeat;
        display: block;
        order: -1;
    }
    .tab_top.open a {
        display: block;
    }
    /* 탭 활성화시 화살표 변경 */
    .tab_top.open .router-link-active {
        background-image:url("~@/assets/image/common/icon_arrow_up.svg");
        order: -1;
    }
}
/* 최상단 탭 END */
</style>