<template>
    <div class="terms c_content_container">
        <section class="c_section c_inner">
            <h1 class="l_page_title">이용약관</h1>

            <LayoutTabTop :navList="navList" />

            <router-view></router-view>

        </section>
    </div>
</template>

<script>
import LayoutTabTop from '@/components/LayoutTabTop'    // 상단 메뉴

export default {
    name : 'Terms',
    components : {
        LayoutTabTop, // 상단 메뉴
    },
    data(){
        return {
            navList : [
                { to : '/terms/privacyPolicy', content : '개인정보 취급방침' },
                { to : '/terms/privacyProtection', content : '개인정보 보호규정' },
                { to : '/terms/emailDenial', content : '이메일 무단수집거부' },
                { to : '/terms/serviceTerms', content : '이용약관' },
            ]
        }
    }
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_terms.css"></style>